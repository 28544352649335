/* ------------------------------------------------------------ *\
	Weather Inline
\* ------------------------------------------------------------ */

.weather-inline {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;

	&__icon {
		display: inline-block;
		vertical-align: middle;
		width: rem(34px);
		height: rem(34px);
		margin-right: rem(4px);
	}
}
