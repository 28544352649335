/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	font-size: rem(14px);
	color: inherit;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;

	.link {
		position: relative;
		text-decoration: none;
		color: inherit;
		transition: color .3s;
		display: block;
	
		&:hover {
			color: $primary;
		}

		&:before {
			content: '';
			position: absolute;
			top: rem(10px);
			left: -5px;
			transform: translateX(-50%);
			width: 4px;
			height: 2px;
			background: currentColor;
		}

		&:first-child:before {
			display: none;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.socials {
		font-size: 14px;

		&.socials__header {
			.link:before {
				display: none;
			}
		}

		.link:before {
			top: 10px;
		}
	}
}
