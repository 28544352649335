/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

html {
	background: $white;
	font-family: $font-family-sans-serif;
	font-size: 1.1713vw; //16px on 1366px
	font-weight: 500;
	line-height: 1.4;
	color: $body-color;
}

img {
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 1rem;
}

h1, h2{
	margin-bottom: rem(20px);
	font-size: rem(50px);
	font-weight: 800;
	line-height: 1.44;
}
h3 {
	font-size: rem(36px);
	line-height: 1.1667;
	font-weight: 800;
}
h4 {
	font-size: rem(18px);
	font-weight: 600;
}
h5 {
	margin-bottom: rem(10px);
	font-size: rem(16px);
	font-weight: 600;
	text-transform: uppercase;
}
h6 {
	font-size: rem(14px);
}

p {
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.text-semi-large {
	font-size: rem(26px);
	line-height: 1.7;
	font-weight: 400;
}

.text-large {
	font-size: rem(24px);
	line-height: 1.7;
}

.noselect {
  -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; 
}


/* === Tablet === */
@include media-breakpoint-down(md) {
	html {
		font-size: 1.757vw; // 24px on 1366px
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	html {
		font-size: 16px;
	}

	h1, h2 {
		font-size: 36px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 18px;
	}
	h5 {
		font-size: 16px;
	}
	h6 {
		font-size: 14px;
	}

	.text-large {
		font-size: 20px;
		line-height: 1.5;
	}
}
