/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

.container {
	width: 100%;
	max-width: rem(1156px);
	padding: 0 rem(60px);
	margin: 0 auto;

	&--large {
		max-width: rem(1290px);
	}

	&--tiny {
		max-width: rem(820px);
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.container {
		padding: 0 20px;
	}
}
