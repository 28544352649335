/* ------------------------------------------------------------ *\
	Section List
\* ------------------------------------------------------------ */

.section-list {
	margin: rem(105px) 0;

	&__subtitle {
		margin-bottom: 0;
		font-weight: 800;
		color: $primary;
	}

	/*  Section List Item  */
	&__item {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: rem(30px) 0;
		border-bottom: 1px solid rgba($body-color, .1);

		&__head {
			width: 50%;
			padding-right: rem(15px);
		}

		&__title {
			margin-bottom: 0;
			font-size: rem(24px);
		}

		&__body {
			width: 50%;
			padding-left: rem(15px);
		}

		&__entry {
			max-width: rem(450px);
		}

		&__image {
			display: none;
			position: absolute;
			opacity: 0;
		}

		&--triple {
			.section-list__item__head {
				width: 30%;
			}

			.section-list__item__media {
				width: 20%;
			}
		}

		&__media {
			&__item:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.section-list {
		margin: 60px 0;

		/*  Section List Item  */
		&__item {
			&__head {
				width: 100%;
				padding-right: 0;
			}

			&__title {
				margin-bottom: 10px;
			}

			&__body {
				width: 100%;
				padding-left: 0;
			}

			&--triple {
				.section-list__item__media, .section-list__item__head {
					width: 100%;
				}

				.section-list__item__media {
					margin-bottom: 10px;
				}

			}
		}
	}
}
