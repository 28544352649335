/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
	counter-reset: projects-counter;

	.dragged {
		.thumb {
			transform: scale(.95);
		}
	}

	&__clip {
		overflow: visible;
		margin: 0 rem(-15px);
	}

	&__slide {
		padding: 0 rem(15px);
	}

	&__paging {
		display: none;
	}

	@include nt-hover {
		.thumb {
			opacity: .7;
		}
	}

	.thumb {
		@include nt-hover {
			opacity: 1;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.slider {
		&__paging {
			display: block;
			margin-bottom: 10px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.slider {
		&__clip {
			margin: 0 -5px;
		}

		&__slide {
			padding: 0 5px;
		}
	}
}
