/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'Rollerscript';
	src: url('../fonts/RollerscriptW03-Rough.woff2') format('woff2'),
			url('../fonts/RollerscriptW03-Rough.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'NotoSans';
	src: url('../fonts/NotoSans-Medium.woff2') format('woff2'),
			url('../fonts/NotoSans-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'NotoSans';
	src: url('../fonts/NotoSans-SemiBold.woff2') format('woff2'),
			url('../fonts/NotoSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

