/* ------------------------------------------------------------ *\
	Paging
\* ------------------------------------------------------------ */

.paging {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	&__next,
	&__prev {
		display: inline-flex;
		vertical-align: middle;
		justify-content: center;
		align-items: center;
		padding: rem(14px) rem(7px);
		border: 0;
		border-radius: 0;
		background: transparent;

		&:hover {
			color: $primary;
		}

		svg {
			width: rem(30px) !important;
			height: rem(18px) !important;
		}
	}
}
