/* ------------------------------------------------------------ *\
	Logo Animated
\* ------------------------------------------------------------ */

.logo-animated {
	display: inline-flex;
	align-items: flex-end;

	&__left,
	&__right {
		display: block;
	}

	svg {
		max-width: 100%;
		max-height: 100%;
	}

	&__middle {
		position: relative;
		display: block;
		overflow: hidden;
		width: 0;
		height: rem(50px);
		margin-right: 2px;
		border-bottom: 6px solid currentColor;
		transition: width .7s ease-in-out;
		will-change: width;

		&__text {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			padding: 0 rem(20px);
			font-family: $font-family-cursive;
			font-size: rem(32px);
			font-weight: 400;
			white-space: nowrap;
			opacity: 0;
			transition: opacity .4s;

			&.active {
				position: static;
				opacity: 1;
				transition: opacity .4s .3s;
			}
		}
	}
}

/* === XLarge === */
@include media-breakpoint-up(xxl) {
	.logo-animated {
		transform: scale(1.4);
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.logo-animated {
		&__left {
			width: 68.6px;
		}

		&__right {
			width: 84.7px;
		}

		&__middle {
			&__text {
				padding: 0 15px;
				font-size: 24px;
			}
		}
	}
}
