/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: rem(40px);
	font-weight: 800;
	color: inherit;

	&__link:not(:hover) {
		opacity: .3;
	}

	&__link-wrapper {
		@include nt-hover {
			& + .nav__image {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&__link {
		text-transform: capitalize;
		display: inline-block;
		vertical-align: middle;		
		color: inherit;
		text-decoration: none;
		transition: opacity .6s;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		transition: opacity .6s, visibility .6s;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba($dark-color, .8);
		}
	}

	&__item {
		padding: 0 rem(40px);
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.nav {
		flex-direction: column;
		text-align: center;

		&__item {
			padding: rem(30px) 0;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.nav {
		padding-top: 40px;
		font-size: 36px;

		&__link:not(:hover) {
			opacity: 1;
		}

		&__item {
			padding: rem(10px) 0;
		}
	}
}
