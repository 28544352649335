/* ------------------------------------------------------------ *\
	Subtitle
\* ------------------------------------------------------------ */

.subtitle {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding-left: rem(90px);
	margin-bottom: rem(10px);
	font-size: rem(16px);
	font-weight: 600;
	text-transform: uppercase;
	transform: translateY(10px);
	vertical-align: middle;
	opacity: 0;
	transition: opacity .5s .3s, transform .5s .3s;

	&:before {
		content: '';
		position: absolute;
		top: rem(11px);
		left: 0;
		width: 0;
		height: 2px;
		background: currentColor;
		transition: width .5s;
		will-change: width;
	}

	&--reversed {
		padding-left: 0;
		padding-right: rem(90px);

		&:before {
			left: auto;
			right: 0;
		}
	}

	&--clean {
		padding-left: 0;
		&:before {
			display: none;
		}
	}

	&--primary {
		color: $primary;
	}

	&--eon {
		color: $eon;
	}

	&--ehr {
		color: $ehr;
	}

	&--legitsign {
		color: $legitsign;
	}

	&--purple {
		color: $purple;
	}

	&--body {
		color: $body-color !important;
	}

	&.is-inview {
		transform: translateY(0px);
		opacity: 1;

		&:before {
			width: rem(60px);
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.subtitle {
		padding-left: 50px;

		&--reversed {
			padding-left: 0;
			padding-right: 50px;
		}

		&.is-inview {
			&:before {
				width: 40px;
			}
		}
	}
}
