/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	font-size: 0;
	color: inherit;
}

/* === XLarge === */
@include media-breakpoint-up(xxl) {
	.logo {
		svg {
			width: 240px;
			height: auto;
		}
	}
}
