/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

/*  Typography  */
$font-family-sans-serif: 'Noto Sans', sans-serif;
$font-family-cursive: 'Rollerscript', cursive;

/*  Colors  */
$body-color: #1A1919;

$dark-color: #1A1919;
$light-color: #EEF4F4;

$primary: #4CC2C5;
$eon: #FF0201;
$legitsign:#00BD94;
$ehr: #289F89;
$purple:#AA6AFF;
$white: #fff;

/*  Grid Breakpoints  */
$grid-breakpoints: (
	xxs: 0,
	xs: 375px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1280px,
	xxl: 1700px
) !default;
