/* ------------------------------------------------------------ *\
	Nav Trigger
\* ------------------------------------------------------------ */

.nav-trigger {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 30px;
	height: 28px;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: transparent;
	font-size: 0;
	line-height: 1;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;

	span {
		position: absolute;
		left: 0;
		display: block;
		width: 100%;
		height: 4px;
		background: currentColor;
		pointer-events: none;

		&:nth-child(1),
		&:nth-child(3) {
			transition: top .2s .2s, transform .2s;
		}

		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2) {
			top: 12px;
			transition: opacity 0s .2s;
		}
		&:nth-child(3) {
			top: 24px;
		}
	}

	.menu-is-opened & {
		span {
			&:nth-child(1),
			&:nth-child(3) {
				top: 12px;
				transition: top .2s, transform .2s .2s;
			}

			&:nth-child(1) {
				transform: rotate(45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: rotate(-45deg);
			}
		}
	}
}

/* === XLarge === */
@include media-breakpoint-up(xxl) {
	.nav-trigger {
		width: 45px;
		height: 42px;

		span {
			height: 6px;

			&:nth-child(2) {
				top: 18px;
			}
			&:nth-child(3) {
				top: 36px;
			}
		}

		.menu-is-opened & {
			span {
				&:nth-child(1),
				&:nth-child(3) {
					top: 18px;
				}
			}
		}
	}
}
