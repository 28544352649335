@import 'variables';
@import 'mixins';
@import 'reset';
@import 'helpers';
@import 'base';
@import 'fonts';

/* ------------------------------------------------------------ *\
	Plugins
\* ------------------------------------------------------------ */

@import '~node_modules/locomotive-scroll/dist/locomotive-scroll.min.css';

/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */

@import './partials/fullsize-image';
@import './partials/subtitle';
@import './partials/logo';
@import './partials/logo-animated';
@import './partials/logo-underline';
@import './partials/socials';
@import './partials/link-more';
@import './partials/weather-inline';
@import './partials/paging';

/* ------------------------------------------------------------ *\
	Main Blocks
\* ------------------------------------------------------------ */

@import './blocks/main/header';
@import './blocks/main/nav';
@import './blocks/main/nav-trigger';
@import './blocks/main/footer';
@import './blocks/main/container';
@import './blocks/main/menu';

/* ------------------------------------------------------------ *\
	Blocks
\* ------------------------------------------------------------ */

@import './blocks/intro';
@import './blocks/media-section';
@import './blocks/section';
@import './blocks/section-slider';
@import './blocks/section-list';
@import './blocks/slider';
@import './blocks/text-image';