/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

.d-none {
	display: none !important;
}

.d-block {
	display: block;
}

.p-relative {
	position: relative !important;
}

.p-fixed {
	position: fixed !important;
}

.w-100 {
	width: 100% !important;
}

/*  Animations  */
.fade-in {
	opacity: 0;
	transition: opacity .5s .3s;

	&.is-inview {
		opacity: 1;
	}
}
.fade-in-up {
	transform: translateY(10px);
	opacity: 0;
	transition: opacity .5s .3s, transform .5s .3s;

	&.is-inview {
		transform: translateY(0px);
		opacity: 1;
	}
}
.fade-in-down {
	transform: translateY(-10px);
	opacity: 0;
	transition: opacity .5s .3s, transform .5s .3s;

	&.is-inview {
		transform: translateY(0px);
		opacity: 1;
	}
}
.fade-in-up-large {
	transform: translateY(80px);
	opacity: 0;
	transition: opacity .5s .3s, transform .6s .3s;

	&.is-inview {
		transform: translateY(0px);
		opacity: 1;
	}
}
