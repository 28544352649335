/* ------------------------------------------------------------ *\
	Media Section
\* ------------------------------------------------------------ */

.media-section {
	overflow: hidden;

	&__wrapper {
		position: relative;
		z-index: 2;
	}

	&__inner {
		position: relative;
		overflow: hidden;
		width: calc(100% + (50vw - 50%));
		margin-right: calc(50% - 50vw);
		background: inherit;
		text-align: center;

		&--shadow {
			border-radius: 5px 0 0 5px;
			box-shadow: -1px 0px 1px -1px rgb(251 251 255);
		}

		&--top {
			z-index: 199;
		}
	}

	&__media {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 56.25%;
	}

	&__video,
	&__image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

	}
	
	&--reversed {
		.media-section__inner {
			margin-right: unset;
			margin-left: calc(50% - 50vw);
		}
	}


	&--padding {
		padding: rem(70px) 0;
	}
	
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.media-section {
		&__image {
			width: 100%;
			margin: 0;
		}
	}
}
