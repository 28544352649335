/* ------------------------------------------------------------ *\
	Link More
\* ------------------------------------------------------------ */

.link-more {
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	border: 0;
	border-radius: 0;
	background: transparent;
	font-size: rem(18px);
	font-weight: 800;
	color: inherit;
	text-decoration: none;
	transition: text-shadow .3s;

	svg {
		display: inline-block;
		margin-top: rem(2px);
		margin-left: rem(15px);
		width: rem(20px) !important;
		height: rem(12px) !important;
	}

	@include nt-hover {
		svg {
			animation: moveArrow 1s infinite;
		}
	}


	&--down {
		@include nt-hover {
			svg {
				animation: moveArrowDown 1s infinite;
			}
		}
	}
}

/* === XLarge === */
@include media-breakpoint-up(xxl) {
	.link-more {
		font-size: 24px;
	}
}

@keyframes moveArrow {
	0% {
		transform: translateX(0px)
	}
	30% {
		transform: translateX(10px)
	}
	100% {
		transform: translateX(0px)
	}
}

@keyframes moveArrowDown {
	0% {
		transform: translateY(0px)
	}
	30% {
		transform: translateY(10px)
	}
	100% {
		transform: translateY(0px)
	}
}
