/* ------------------------------------------------------------ *\
	Mixins
\* ------------------------------------------------------------ */

/*  Boostram grid mixins  */
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

/*  Disable hover for touch devices  */
@mixin nt-hover {
	.no-touchevents &:hover {
		@content;
	}
}

/*
	Typography
*/
@function rem($size) {
  @return calc($size / 16px * 1rem);
}


