/* ------------------------------------------------------------ *\
	Menu
\* ------------------------------------------------------------ */

@mixin Background($url) {
	background: linear-gradient(rgba(26, 25, 25, 0.7), rgba(26, 25, 25, 0.7)), url($url);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.menu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	background: $dark-color;
	color: $white;
	transform: translateY(-100%);
	transition: transform .4s .6s ease-in-out;

	.menu-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: .3s ease-in-out opacity;
		opacity: 1;

		&[data-page="home"] {
			@include Background('../images/header/home.jpg');
		}
		&[data-page="approach"] {
			@include Background('../images/header/approach.jpg');
		}
		&[data-page="culture"] {
			@include Background('../images/header/culture.jpg');
		}
		&[data-page="tribe"] {
			@include Background('../images/header/tribe.jpg');
		}
		&[data-page="web3"] {
			@include Background('../images/header/web3.jpg');
		}
		&.background-hidden {
			opacity: 0;
		}
	}
	.nav {
		.nav__link-wrapper {
			opacity: 0;
			transform: translateY(-20px);
		}

		.nav__item {
			@for $i from 1 through 4 {
				&:nth-last-child(#{$i}) {
					.nav__link-wrapper {
						transition:
						opacity .4s $i*0.125s,
						transform .4s $i*0.125s;
					}
				}
			}
		}
	}

	.menu-is-opened & {
		transform: translateY(0%);
		transition: transform .4s ease-in-out;

		.nav .nav__item {
			.nav__link-wrapper {
				opacity: 1;
				transform: translateY(0px);
			}

			@for $i from 1 through 4 {
				&:nth-child(#{$i}) {
					.nav__inner {
						transition:
							opacity .4s .4+$i*0.125s,
							transform .4s .4+$i*0.125s;
					}
				}
			}
		}
		// Prefetch background images when the menu is open
		&::after{
			position:absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
			content: 
				url('../images/header/home.jpg') 
				url('../images/header/approach.jpg') 
				url('../images/header/culture.jpg') 
				url('../images/header/tribe.jpg');
	 	}
	}

	&__container {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		overflow-x: hidden;
		height: 100vh;
		padding: rem(30px) 0;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s .4s, visibility .3s .4s;

		.menu-is-opened & {
			opacity: 1;
			visibility: visible;
			transition: opacity .3s .4s, visibility .3s .4s;
		}
	}

	&__top {
		padding-bottom: rem(70px);
	}

	&__middle {
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__bottom {
		position: relative;
		display: flex;
		justify-content: space-between;
		padding: rem(60px) 0 rem(30px);
		border-top: 1px solid rgba($white, .3);
	}

	&__message {
		font-size: rem(20px);
		color: rgba($white, .7);

		strong {
			font-weight: 600;
		}

		a {
			color: $white;
			text-decoration: none;
			transition: color .3s;

			&:hover {
				color: $primary;
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.menu {
		padding: 0;
		&__top {
			padding-bottom: 0;
		}

		// Prefetch background images when the menu is open
		.menu-is-opened & {
			&::after {
				position:absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
				content: 
					url('../images/header/home-tablet.jpg') 
					url('../images/header/approach-tablet.jpg') 
					url('../images/header/culture-tablet.jpg') 
					url('../images/header/tribe-tablet.jpg');
			}
		}

		.menu-background {
			transition: opacity .3s linear;
			&[data-page=""] {
				opacity: 0;
			}
			&[data-page="home"] {
				@include Background('../images/header/home-tablet.jpg');
			}
			&[data-page="approach"] {
				@include Background('../images/header/approach-tablet.jpg');
			}
			&[data-page="culture"] {
				@include Background('../images/header/culture-tablet.jpg');
			}
			&[data-page="tribe"] {
				@include Background('../images/header/tribe-tablet.jpg');
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {

	.menu {
		// Prefetch background images when the menu is open
		.menu-is-opened & {
			&::after {
				position:absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
				content: 
					url('../images/header/home-mobile.jpg') 
					url('../images/header/approach-mobile.jpg') 
					url('../images/header/culture-mobile.jpg') 
					url('../images/header/tribe-mobile.jpg');
			}
		}

		background: #1A1919;
		&.home-page {
			@include Background('../images/header/home-mobile.jpg');
		}
		&.approach-page {
			@include Background('../images/header/approach-mobile.jpg');
		}
		&.culture-page {
			@include Background('../images/header/culture-mobile.jpg');
		}
		&.tribe-page {
			@include Background('../images/header/tribe-mobile.jpg');
		}

		&__container {
			padding: 20px 0;
		}

		&__bottom {
			display: block;
			text-align: center;
			padding: 30px 0 10px;
		}

		&__message {
			margin-bottom: 20px;
			font-size: 18px;
		}

		.socials {
			display: inline-block;
		}
	}
}
