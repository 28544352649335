/* ------------------------------------------------------------ *\
	Section Slider
\* ------------------------------------------------------------ */

.section-slider {
	position: relative;
	overflow: hidden;
	padding: rem(105px) 0;

	&__head {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding-bottom: rem(95px);
	}

	&__title {
		width: 50%;
		max-width: rem(400px);
		margin-bottom: 0;
	}

	&__entry {
		width: 50%;
		padding-right: rem(40px);
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.section-slider {
		&__title {
			width: 100%;
			max-width: 100%;
			margin-bottom: rem(20px);
		}

		&__entry {
			width: 100%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.section-slider {
		padding: 60px 0;

		&__head {
			padding-bottom: 60px;
		}
	}
}
