/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	margin: rem(180px) 0 rem(100px);

	&__head {
		max-width: rem(920px);
		margin: 0 auto rem(60px);
		text-align: center;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.section {
		margin-top: rem(120px);
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.section {
		margin: 60px 0;
	}
}
