/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	.menu-is-opened & {
		/*
			Remove background when menu animations has finished,
			so nav link image takes the whole screen
		*/
		&.scrolled {
			background: transparent;
			transition: background 0s 1.2s;
		}

		/*
			Hide element when menu animations has finished,
			so nav link image takes the whole screen
		*/
		&:before {
			opacity: 0;
			transform: translateY(0%);
			transition: transform .105s, opacity 0s 1.2s;
		}
	}

	.menu-is-closing & {
		.logo,
		.nav-trigger {
			transition: color .3s .7s;
		}
	}

	.nav-trigger {
		.menu-is-opened & {
			color: $white;
			transition: color .3s;
		}
	}

	.logo {
		.menu-is-opened & {
			color: $white;
			transition: color .3s;
		}
	}

	&--light {
		.nav-trigger {
			color: $white;
		}

		.logo {
			color: $primary;
		}
	}

	&.scrolled {
		background: inherit;
		color: inherit;
	}

	&.scrolling-down {
		transform: translateY(-100%);
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.header {
		&__inner {
			padding: 20px 0;
		}
	}
}
