/* ------------------------------------------------------------ *\
	Text Image
\* ------------------------------------------------------------ */

.text-image {
	padding: rem(105px) 0;

	&__inner {
		display: flex;
		flex-wrap: wrap;
	}

	&__content {
		width: 50%;
		padding: rem(32px) rem(20px) rem(32px) 0;
	}

	&__entry {
		margin-bottom: rem(30px);
	}

	&__media {
		width: 50%;
	}

	&__image {
		img {
			border-radius: 5px;
		}

		&__decoration {
			position: absolute;
			top: rem(50px);
			right: rem(-60px);
			bottom: rem(-40px);
			left: rem(32px);
			z-index: -1;
			border-radius: 5px;
			background: rgba(#A9C8C6, .5);

			&--dark {
				background: rgba(#3B3B3D, .5);
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.text-image {
		&__content {
			width: 100%;
			padding: 0;
			margin-bottom: rem(60px);
		}

		&__media {
			width: 100%;
			padding-right: rem(60px);
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.text-image {
		padding: 60px 0;
	}
}
